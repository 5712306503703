import {useFetchers, useLocation, useMatches} from "@remix-run/react"
import {
  AnalyticsEventName,
  AnalyticsPageType,
  getClientBrowserParameters,
  sendShopifyAnalytics,
  ShopifyAddToCartPayload,
  ShopifyPageViewPayload,
  useShopifyCookies,
} from "@shopify/hydrogen"
import {useEffect} from "react"

import {CurrencyCode, LanguageCode} from "~/graphql"
import {CartAction} from "~/types/cart"

export const useAnalytics = (hasUserConsent: boolean, domain: string) => {
  useShopifyCookies({hasUserConsent, domain})
  const location = useLocation()
  const analyticsFromMatches = useDataFromMatches(
    "analytics",
  ) as unknown as ShopifyPageViewPayload

  const pageAnalytics = {
    ...analyticsFromMatches,
    pageType: analyticsFromMatches.pageType ?? AnalyticsPageType.page,
    currency: CurrencyCode.Usd,
    acceptedLanguage: LanguageCode.En,
    hasUserConsent,
  }

  useEffect(() => {
    const payload: ShopifyPageViewPayload = {
      ...getClientBrowserParameters(),
      ...pageAnalytics,
    }

    sendShopifyAnalytics({
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const cartData = useDataFromFetchers({
    formDataKey: "cartAction",
    formDataValue: CartAction.ADD_TO_CART,
    dataKey: "analytics",
  }) as unknown as ShopifyAddToCartPayload

  if (cartData) {
    const addToCartPayload: ShopifyAddToCartPayload = {
      ...getClientBrowserParameters(),
      ...pageAnalytics,
      ...cartData,
    }

    sendShopifyAnalytics({
      eventName: AnalyticsEventName.ADD_TO_CART,
      payload: addToCartPayload,
    })
  }
}

const useDataFromMatches = (dataKey: string): Record<string, unknown> => {
  const matches = useMatches()
  const data: Record<string, unknown> = {}

  matches.forEach((event) => {
    const eventData = event?.data
    if (eventData && eventData[dataKey]) {
      Object.assign(data, eventData[dataKey])
    }
  })

  return data
}

const useDataFromFetchers = ({
  formDataKey,
  formDataValue,
  dataKey,
}: {
  formDataKey: string
  formDataValue: unknown
  dataKey: string
}): Record<string, unknown> | undefined => {
  const fetchers = useFetchers()
  const data: Record<string, unknown> = {}

  for (const fetcher of fetchers) {
    const formData = fetcher.formData
    const fetcherData = fetcher.data
    if (
      formData &&
      formData.get(formDataKey) === formDataValue &&
      fetcherData &&
      fetcherData[dataKey]
    ) {
      Object.assign(data, fetcherData[dataKey])

      try {
        if (formData.get(dataKey)) {
          const dataInForm: unknown = JSON.parse(String(formData.get(dataKey)))
          Object.assign(data, dataInForm)
        }
      } catch {
        // do nothing
      }
    }
  }
  return Object.keys(data).length ? data : undefined
}
