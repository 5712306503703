import {useFetcher, useLocation} from "@remix-run/react"

import {useToggle} from "~/hooks/use-toggle"
import {CookieConsent, CookieConsentRequestOption} from "~/types/cookie-consent"

import {Button} from "../elements/Button"
import {Checkbox} from "../elements/Checkbox"
import {IconClose} from "../elements/Icon"
import {Modal} from "./Modal"

type CookieConsentBannerProps = {active: boolean}

export const CookieConsentBanner = ({active}: CookieConsentBannerProps) => {
  const {pathname, search} = useLocation()
  const fetcher = useFetcher()
  const [showModal, toggleModal] = useToggle(false)

  const handleSubmit = ({analytics, marketing}: CookieConsent) => {
    fetcher.submit(
      new URLSearchParams({
        ...(!!analytics && {analytics: CookieConsentRequestOption.ACCEPT}),
        ...(!!marketing && {marketing: CookieConsentRequestOption.ACCEPT}),
        redirect: `${pathname}${search}`,
      }),
      {action: "/api/cookie-consent", method: "post"},
    )
  }

  if (!active) {
    return null
  }

  if (showModal) {
    return <CookieConsentModal submit={handleSubmit} onClose={toggleModal} />
  }

  return (
    <div className="z-50 fixed bottom-0 flex max-md:flex-col w-full p-2 gap-2 md:gap-3 bg-white">
      <div className="flex flex-col gap-2 md:gap-1">
        <h3 className="text-subheading-2">We value your privacy</h3>
        <p>
          We use cookies to provide you with the best possible user experience.
          We respect your privacy and we are committed to protecting it. By
          clicking “Accept all” you consent to our use of cookies.
        </p>
      </div>
      <div className="flex gap-1 items-end md:ml-auto whitespace-nowrap max-md:flex-wrap">
        <Button className="flex-1" variant="secondary" onClick={toggleModal}>
          Let me choose
        </Button>
        <Button
          className="flex-1"
          variant="secondary"
          onClick={() =>
            handleSubmit({
              analytics: false,
              marketing: false,
            })
          }
        >
          Decline
        </Button>
        <Button
          width="full"
          variant="tertiary"
          onClick={() =>
            handleSubmit({
              analytics: true,
              marketing: true,
            })
          }
        >
          Accept all
        </Button>
      </div>
    </div>
  )
}
type CookieConsentModalProps = {
  submit: (args: CookieConsent) => void
  onClose: () => void
}
const CookieConsentModal = ({submit, onClose}: CookieConsentModalProps) => {
  const [analytics, toggleAnalytics] = useToggle(false)
  const [marketing, toggleMarketing] = useToggle(false)

  return (
    <Modal active={true} onClose={onClose}>
      <div className="relative flex flex-col max-w-[768px] p-2 md:p-3 gap-3 rounded-xl bg-white">
        <IconClose
          stroke="cosmosgrey"
          onClick={onClose}
          className="cursor-pointer absolute top-2 right-2"
        />
        <h3 className="text-subheading">Cookie settings</h3>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1 md:gap-5">
            <div className="flex flex-col gap-1">
              <h4 className="text-black text-subheading-2">Necessary</h4>
              <p>
                Essential for the website&apos;s operation and cannot be
                disabled. They allow you to navigate the website and use its
                features, such as accessing secure areas of the site.
              </p>
            </div>
            <Checkbox
              id="necessary-cookies"
              name="necessary"
              disabled
              checked
            />
          </div>
          <div className="flex items-center gap-1 md:gap-5">
            <div className="flex flex-col gap-1">
              <h4 className="text-black text-subheading-2">Statistics</h4>
              <p>
                Also known as analytics cookies, these cookies collect
                information about how you use the website, such as which pages
                you visit most often.
              </p>
            </div>
            <Checkbox
              name="analytics"
              checked={analytics}
              onChange={toggleAnalytics}
            />
          </div>
          <div className="flex items-center gap-1 md:gap-5">
            <div className="flex flex-col gap-1">
              <h4 className="text-black text-subheading-2">Marketing</h4>
              <p>
                Used to track your browsing behavior and serve you targeted ads.
                These cookies allow you to share content from the website on
                social media platforms, such as Facebook or Twitter.
              </p>
            </div>
            <Checkbox
              name="marketing"
              checked={marketing}
              onChange={toggleMarketing}
            />
          </div>
        </div>
        <div className="flex max-md:flex-col gap-1 items-end md:ml-auto whitespace-nowrap">
          <Button
            width="full"
            variant="secondary"
            onClick={() =>
              submit({
                analytics: false,
                marketing: false,
              })
            }
          >
            Decline all
          </Button>
          <Button
            width="full"
            variant="tertiary"
            onClick={() =>
              submit({
                analytics,
                marketing,
              })
            }
          >
            Save & exit
          </Button>
        </div>
      </div>
    </Modal>
  )
}
