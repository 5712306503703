import {Await, useFetchers} from "@remix-run/react"
import {Money} from "@shopify/hydrogen"
import {Suspense, useEffect, useRef, useState} from "react"

import {useRootLoaderData} from "~/hooks/use-root-loader-data"
import {CartAction} from "~/types/cart"
import {flattenExactConnection} from "~/utilities/flatten-exact-connection"

import {CartDrawerLineItemCard} from "../cards/CartDrawerLineItemCard"
import {Button} from "../elements/Button"
import {IconCircleCheck} from "../elements/Icon"
import {Drawer} from "./Drawer"

export type CartDrawerProps = {
  active: boolean
  onClose: () => void
}

export const CartDrawer = ({active, onClose}: CartDrawerProps) => {
  const {cart} = useRootLoaderData()
  const [title, setTitle] = useState("")
  const checkoutBtnRef = useRef(null)

  const fetchers = useFetchers()

  const fetcher = fetchers.find((fetcher) => {
    return fetcher.formData?.get("cartAction")
  })

  useEffect(() => {
    const action = fetcher?.formData?.get("cartAction")
    const lines = fetcher?.formData?.get("lines")

    switch (action) {
      case CartAction.ADD_TO_CART: {
        const quantity = JSON.parse(lines?.toString() ?? "[]")[0]?.quantity

        setTitle(`Item${quantity > 1 ? "s" : ""} successfully added`)
        break
      }
      case CartAction.UPDATE_CART: {
        const quantity = JSON.parse(lines?.toString() ?? "{}").quantity

        if (quantity) {
          setTitle(`Item${quantity > 1 ? "s" : ""} successfully updated`)
        } else {
          setTitle("Item successfully removed")
        }
        break
      }
    }
  }, [fetcher, setTitle])

  return (
    <Drawer
      side="right"
      active={active}
      onClose={onClose}
      initialFocus={checkoutBtnRef}
    >
      <Suspense>
        <Await resolve={cart}>
          {(cart) => (
            <>
              {title ? (
                <div className="flex gap-1 mb-3">
                  <IconCircleCheck />
                  <h3 className="text-subheading-2 font-semibold">{title}</h3>
                </div>
              ) : (
                <h3 className="mb-3 text-subheading-2 font-semibold">
                  Your cart{" "}
                  {!!cart?.totalQuantity && <>({cart.totalQuantity})</>}
                </h3>
              )}
              <div className="flex flex-col gap-3 overflow-y-auto mb-3">
                {flattenExactConnection(cart?.lines).map((item) => (
                  <CartDrawerLineItemCard key={item.id} lineItem={item} />
                ))}
              </div>
              <div className="flex mt-auto justify-between text-subheading-2 font-bold text-cosmosgrey">
                <div>Total</div>
                {cart?.cost.subtotalAmount?.amount ? (
                  <Money data={cart.cost.subtotalAmount} />
                ) : (
                  "-"
                )}
              </div>
              <div className="mt-2">
                <Button
                  to={cart?.checkoutUrl}
                  size="lg"
                  as="link"
                  width="full"
                  variant="tertiary"
                  disabled={!cart?.totalQuantity}
                  ref={checkoutBtnRef}
                >
                  Checkout
                </Button>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </Drawer>
  )
}
