import {Image, Money} from "@shopify/hydrogen"

import {CartLineItem} from "~/types/cart"

import {UpdateCartInput} from "../products/UpdateCartInput"

type CartDrawerLineItemCardProps = {
  lineItem: CartLineItem
}

export const CartDrawerLineItemCard = ({
  lineItem,
}: CartDrawerLineItemCardProps) => {
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex items-start gap-1">
        {lineItem.merchandise.image && (
          <Image data={lineItem.merchandise.image} width={50} height={50} />
        )}
        <div className="font-bold text-cosmosgrey">
          {lineItem.merchandise.product.title}
        </div>
      </div>

      <div className="flex self-end items-center gap-1 md:gap-2">
        <UpdateCartInput lineItem={lineItem} showDeleteOption={true} />
        <Money data={lineItem.cost.totalAmount} />
      </div>
    </div>
  )
}
