import {handleFromTags} from "./collection-tag"

type ResourceItem =
  | {
      handle: string
    }
  | {
      handle: string
      tags: string[]
    }

export const resourcePath = <T extends ResourceItem>(item: T) => {
  if ("tags" in item) {
    const collectionHandle = handleFromTags(item.tags)

    return `/products/${collectionHandle}/${item.handle}`
  }

  return `/products${item.handle === "all" ? "" : `/${item.handle}`}`
}
