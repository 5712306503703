import {EnhancedMenu} from "~/types/enhanced-menu"

import {Footer} from "../global/Footer"
import {Header} from "../global/Header"

type LayoutProps = {
  children: React.ReactNode
  layout: {
    headerMenu?: EnhancedMenu
    footerMenu?: EnhancedMenu
  }
}

export const Layout = ({children, layout}: LayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header menu={layout?.headerMenu} />
      <main className="grow">{children}</main>
      <Footer menu={layout?.footerMenu} />
    </div>
  )
}
