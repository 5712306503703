import {useLocation} from "@remix-run/react"
import {useEffect, useState} from "react"

type UseScript = {
  location: "head" | "body"
  innerHTML?: string
  ignore?: boolean
  preventReset?: boolean
}

type ScriptProps = Omit<
  React.DetailedHTMLProps<
    React.ScriptHTMLAttributes<HTMLScriptElement>,
    HTMLScriptElement
  >,
  "dangerouslySetInnerHTML"
>

export const useScript = (
  {location, innerHTML, ignore, preventReset}: UseScript,
  props?: ScriptProps,
) => {
  const [loaded, setLoaded] = useState(false)
  const {pathname} = useLocation()

  useEffect(() => {
    if (ignore || (preventReset && loaded)) return

    const script = document.createElement("script")
    for (const [key, value] of Object.entries(props ?? [])) {
      script.setAttribute(key, value)
    }

    if (innerHTML) {
      script.innerHTML = innerHTML
    }

    document[location].appendChild(script)

    setLoaded(true)

    if (!preventReset) {
      return () => {
        document[location].removeChild(script)
      }
    }
  }, [ignore, innerHTML, loaded, location, pathname, preventReset, props])
}
