import {CookieConsent} from "~/types/cookie-consent"

import {useRootLoaderData} from "./use-root-loader-data"
import {useScript} from "./use-script"

type UseTrackingScriptsArgs = {
  app: Window["app"]
  cookieConsent: CookieConsent
  nonce?: string
}
export const useTrackingScripts = ({nonce}: UseTrackingScriptsArgs) => {
  const {app, cookieConsent} = useRootLoaderData()

  // ga script
  useScript(
    {
      ignore: app.isDevelopment || !cookieConsent.analytics,
      location: "head",
      preventReset: true,
    },
    {
      src: `https://www.googletagmanager.com/gtag/js?id=${app.gaId}`,
      async: true,
      nonce,
      suppressHydrationWarning: true,
    },
  )

  // ga datalayer script
  useScript(
    {
      ignore: app.isDevelopment || !cookieConsent.analytics,
      preventReset: true,
      location: "head",
      innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${app.gaId}', {
          send_page_view: false,
        });
      `,
    },
    {
      nonce,
      suppressHydrationWarning: true,
    },
  )

  // gorgias script
  useScript(
    {
      ignore: app.isDevelopment || !cookieConsent.analytics,
      location: "body",
      preventReset: true,
    },
    {
      src: `https://config.gorgias.chat/bundle-loader/${app.gorgiasId}`,
      id: "gorgias-chat-widget-install-v3",
      nonce,
      suppressHydrationWarning: true,
    },
  )

  // klaviyo email sign-up
  useScript(
    {
      location: "head",
    },
    {
      type: "text/javascript",
      src: "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=QYJtCc",
    },
  )

  // hotjar initialize global object
  useScript(
    {
      ignore: app.isDevelopment || !cookieConsent.analytics,
      location: "head",
      innerHTML: `
        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: ${app.hotjarId}, hjsv: 6 };
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `,
    },
    {
      nonce,
      suppressHydrationWarning: true,
    },
  )

  // hotjar script
  useScript(
    {
      ignore: app.isDevelopment || !cookieConsent.analytics,
      location: "head",
    },
    {
      src: `https://static.hotjar.com/c/hotjar-${app.hotjarId}.js?sv=6`,
      async: true,
      nonce,
      suppressHydrationWarning: true,
    },
  )
}
