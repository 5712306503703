import {Dialog} from "@headlessui/react"
import clsx from "clsx"
import {cloneElement, useMemo} from "react"

import {useToggle} from "~/hooks/use-toggle"

import {IconClose} from "../elements/Icon"

type DrawerProps = {
  side?: "left" | "right"
  initialFocus?: React.MutableRefObject<HTMLElement | null>
  children: React.ReactNode
} & (
  | {
      activator?: React.ReactElement
      active?: never
      onClose?: never
    }
  | {
      activator?: never
      active?: boolean
      onClose?: () => void
    }
)

export const Drawer = ({
  side = "left",
  initialFocus,
  activator,
  active,
  onClose,
  children,
}: DrawerProps) => {
  const [isOpen, toggleIsOpen] = useToggle()

  const activatorMarkup = useMemo(
    () =>
      activator
        ? cloneElement(activator, {
            onClick: toggleIsOpen,
          })
        : null,
    [activator, toggleIsOpen],
  )

  return (
    <>
      {activatorMarkup}
      <Dialog
        open={active ?? isOpen}
        onClose={onClose ?? toggleIsOpen}
        className="relative z-50"
        onPointerDown={(e: React.SyntheticEvent) => e.stopPropagation()}
        initialFocus={initialFocus}
      >
        <div className="fixed inset-0 bg-spacemist/80 overflow-y-auto" />

        <div
          className={clsx(
            "fixed inset-0 flex items-start",
            side === "left" ? "justify-start" : "justify-end",
          )}
        >
          <Dialog.Panel className="relative flex flex-col w-3/4 max-w-[375px] h-screen p-[20px] max-md:pb-5 bg-white">
            <IconClose
              stroke="cosmosgrey"
              onClick={onClose ?? toggleIsOpen}
              className="cursor-pointer absolute top-[20px] right-[20px]"
            />
            {children}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}
