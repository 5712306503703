import {useClient} from "~/hooks/use-client"
import {EnhancedMenu} from "~/types/enhanced-menu"
import {formatPhone} from "~/utilities/format-phone"

import {FooterMenu} from "./FooterMenu"

type FooterProps = {
  menu?: EnhancedMenu
}

export const Footer = ({menu}: FooterProps) => {
  const {isClient} = useClient()

  return (
    <section
      role="contentinfo"
      className="grid grid-cols-1 md:grid-cols-12 gap-[90px] md:gap-3 md:gap-x-3 md:gap-y-4 pt-1 md:pt-3 pb-2 md:px-2 bg-gradient-to-br from-[#1D2024] to-[#30343A] text-ash"
    >
      <div className="col-span-1 md:col-span-4 order-3 md:order-1 text-center md:text-left">
        <h3 className="text-titled text-white font-semibold mb-2">
          We provide industry solutions
        </h3>
        <p>
          The reliability and expertise your business needs to grow and
          optimize.
        </p>
      </div>
      <FooterMenu menu={menu} />
      <div className="col-span-1 md:col-span-3 order-4 text-center md:text-left">
        <h3 className="text-titled text-white font-semibold mb-1 md:mb-2">
          Call us, we&apos;re ready.
        </h3>
        {!!isClient && (
          <div className="flex flex-col">
            {window.app.phone && (
              <p className="md:order-1">{formatPhone(window.app.phone)}</p>
            )}
            {window.app.startTime && window.app.endTime && window.app.timezone && (
              <p className="md:order-2">
                {window.app.startTime} - {window.app.endTime}{" "}
                {window.app.timezone}
              </p>
            )}
            {window.app.email && (
              <p className="md:mt-2 md:order-4">{window.app.email}</p>
            )}
            {window.app.address && (
              <p className="mt-4 md:mt-2 flex flex-col md:order-3">
                {window.app.address.split("\n").map((line, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={idx}>{line}</span>
                ))}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="col-span-1 md:col-span-12 order-last">
        {!!isClient && window.app.name && (
          <p className="text-center">
            Copyright &copy; {new Date().getFullYear()} {window.app.name}
          </p>
        )}
      </div>
    </section>
  )
}
