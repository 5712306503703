import { defer, LinksFunction, LoaderArgs, redirect } from "@remix-run/cloudflare";
import {
isRouteErrorResponse,
Links,
LiveReload,
Meta,
Outlet,
Scripts,
ScrollRestoration,
useLoaderData,
useLocation,
useMatches,
useOutletContext,
useRouteError } from
"@remix-run/react";
import { withSentry } from "@sentry/remix";
import {
I18nBase,
Seo,
SeoHandleFunction,
ShopifySalesChannel,
Storefront } from
"@shopify/hydrogen";
import { useEffect, useMemo, useState } from "react";

import bgImage404 from "~/images/error/404.jpg";
import bgImage500 from "~/images/error/500.jpg";
import logo from "~/images/logo.png";
import icon404 from "~/images/svgs/404.svg";
import icon500 from "~/images/svgs/500.svg";
import { parseMenu } from "~/utilities/parse-menu";

import { CartDrawer } from "./components/global/CartDrawer";
import { CookieConsentBanner } from "./components/global/CookieConsentBanner";
import { useNonce } from "./components/global/NonceContext";
import { Layout } from "./components/layouts/Layout";
import {
CartQuery,
CartQueryVariables,
LayoutQuery,
LayoutQueryVariables } from
"./graphql";
import { CART_QUERY } from "./graphql/queries/cart";
import { LAYOUT_QUERY } from "./graphql/queries/layout";
import { useAnalytics } from "./hooks/use-analytics";
import { useGA4Events } from "./hooks/use-ga4-events";
import { useTrackingScripts } from "./hooks/use-tracking-scripts";
import styles from "./styles/app.css";
import { HydrogenSession } from "./utilities/session.server";

const seo: SeoHandleFunction<typeof loader> = ({ data: { app }, pathname }) => ({
  title: "Enterprise software",
  titleTemplate: `%s | ${app.name}`,
  description: app.description,
  url: `${app.url}${pathname.replace(/\/+$/, "")}`,
  jsonLd: [
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    legalName: app.name,
    name: app.name,
    url: app.url,
    email: app.email,
    logo: `${app.url}${logo}`,
    contactPoint: {
      "@type": "ContactPoint",
      telephone: `+1-${app.phone}`,
      contactType: "customer support",
      contactOption: "TollFree",
      areaServed: ["US", "CA"],
      availableLanguage: ["English", "Spanish"]
    }
  },
  {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: app.name,
    url: app.url
  }]

});

export const handle = { seo };

export const links: LinksFunction = () => {
  return [
  {
    rel: "stylesheet",
    href: styles
  },
  {
    rel: "preconnect",
    href: "https://cdn.shopify.com"
  },
  {
    rel: "preconnect",
    href: "https://shop.app"
  },
  {
    rel: "preconnect",
    href: "https://fonts.googleapis.com"
  },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com"
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap"
  },
  {
    rel: "icon",
    type: "image/x-icon",
    href: "/favicon/favicon.ico"
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    type: "image/x-icon",
    href: "/favicon/apple-touch-icon-180.png"
  },
  {
    rel: "manifest",
    href: "/favicon/site.webmanifest"
  }];

};

export const loader = async ({
  request,
  context: { storefront, session, env }
}: LoaderArgs) => {
  const url = new URL(request.url);
  const hostname = url.hostname;
  const proto = request.headers.get("X-Forwarded-Proto") ?? url.protocol;

  url.host =
  request.headers.get("X-Forwarded-Host") ??
  request.headers.get("host") ??
  url.host;
  url.protocol = "https:";

  if (proto === "http" && hostname !== "localhost") {
    throw redirect(url.toString(), {
      status: 301,
      headers: {
        "X-Forwarded-Proto": "https"
      }
    });
  }

  const { pathname } = new URL(request.url);
  if (pathname !== "/" && pathname.match(/\/+$/)) {
    throw redirect(pathname.replace(/\/+$/, ""), 301);
  }

  const cart = getCartData(storefront, session);
  const { shop, customer, ...menus } = await getLayoutData(storefront, session);

  const app: Window["app"] = {
    ...(process.env.NODE_ENV === "development" && { isDevelopment: true }),
    ...(typeof env.PUBLIC_RELEASE_VERSION !== "undefined" && {
      version: env.PUBLIC_RELEASE_VERSION
    }),
    ...(typeof env.PUBLIC_SENTRY_DSN !== "undefined" && {
      sentryDsn: env.PUBLIC_SENTRY_DSN
    }),
    gaId: env.PUBLIC_GA_ID,
    gorgiasId: env.PUBLIC_GORGIAS_ID,
    hotjarId: env.PUBLIC_HOTJAR_ID,
    url: env.PUBLIC_SHOP_URL,
    name: shop.name,
    description: shop.description,
    address: shop.address?.value,
    phone: shop.phone?.value,
    email: shop.email?.value,
    startTime: shop.startTime?.value,
    endTime: shop.endTime?.value,
    timezone: shop.timezone?.value
  };

  return defer({
    layout: menus,
    cart,
    app,
    // TODO: use cookie consent
    // cookieConsent: ((await cookieConsent.parse(
    //   request.headers.get("Cookie"),
    // )) || {}) as CookieConsent,
    cookieConsent: {
      necessary: true,
      analytics: true,
      marketing: true
    },
    analytics: {
      shopifySalesChannel: ShopifySalesChannel.headless,
      shopId: shop.id,
      ...(!!customer && {
        customerId: parseInt(
        customer.id.replace("gid://shopify/Customer/", "")
        )
      })
    }
  });
};

const Root = () => {
  const { layout, app, cookieConsent } = useLoaderData<typeof loader>();
  const location = useLocation();
  const nonce = useNonce();
  const [cartActive, setCartActive] = useState(false);

  // TODO: review cookie consent rules used below
  useAnalytics(true, app.url.replace("https://", ""));
  useTrackingScripts({
    app,
    nonce,
    cookieConsent
  });

  const { pageView } = useGA4Events();
  useEffect(() => {
    pageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Seo />
        <meta
        name="google-site-verification"
        content="mlWDza0WSSu5xGVfprPrzraXUDKXc9mVTNsUNqJ_2yY" />

        <Meta />
        <Links />
      </head>
      <body>
        <Layout layout={layout}>
          <Outlet context={{ cartActive, setCartActive }} />
          <CartDrawer
          active={cartActive}
          onClose={() => setCartActive(false)} />

          <CookieConsentBanner active={!Object.keys(cookieConsent).length} />
        </Layout>
        <script
        id="app"
        nonce={nonce}
        suppressHydrationWarning
        dangerouslySetInnerHTML={{
          __html: `window.app = ${JSON.stringify(app)}`
        }} />

        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
      </body>
    </html>);

};

export type RootLoaderData = Awaited<ReturnType<typeof loader>>["data"];

type AppOutletContext = {
  cartActive: boolean;
  setCartActive: (active: boolean) => void;
};

export const useAppOutletContext = () => {
  return useOutletContext<AppOutletContext>();
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  const [root] = useMatches();
  const nonce = useNonce();

  const isRouteError = isRouteErrorResponse(error);

  const title = useMemo(() => {
    if (isRouteError) {
      if (error.status === 400) {
        return "Bad request";
      }
      if (error.status === 401) {
        return "Unauthorized";
      }

      if (error.status === 404) {
        return "Not found";
      }
    }

    return "Error";
  }, [error, isRouteError]);

  const content = useMemo(() => {
    if (root.data?.app?.isDevelopment) {
      return <pre>{JSON.stringify(error, null, 2)}</pre>;
    }

    const is404 = isRouteError && error.status === 404;

    return (
      <div
      className="flex flex-col justify-center items-center gap-3 text-white bg-center bg-cover bg-no-repeat relative h-[calc(100vh-56px)] md:h-[calc(100vh-72px)]"
      style={{ backgroundImage: `url(${is404 ? bgImage404 : bgImage500})` }}>

        <img src={is404 ? icon404 : icon500} alt={is404 ? "404" : "500"} />
        <div className="flex flex-col gap-1 text-center">
          <h1 className="text-subheading-2 text-white font-semibold">
            {is404 ? "Page not found" : "Something went wrong"}
          </h1>
          {!is404 &&
          <p>
              This page encountered an error. Refresh the page or try again
              later.
            </p>}

        </div>
      </div>);

  }, [error, isRouteError, root.data?.app?.isDevelopment]);

  return (
    <html lang="en">
      <head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta
        name="google-site-verification"
        content="mlWDza0WSSu5xGVfprPrzraXUDKXc9mVTNsUNqJ_2yY" />

        <Meta />
        <Links />
      </head>
      <body>
        <Layout layout={root?.data?.layout}>{content}</Layout>
        <script
        id="app"
        nonce={nonce}
        suppressHydrationWarning
        dangerouslySetInnerHTML={{
          __html: `window.app = ${JSON.stringify(root?.data?.app)}`
        }} />

        <Scripts />
      </body>
    </html>);

};

const getLayoutData = async (
storefront: Storefront<I18nBase>,
session: HydrogenSession) =>
{
  const customerAccessToken = (await session.get("customerAccessToken")) ?? "";
  const variables: LayoutQueryVariables = {
    headerMenuHandle: "main-menu",
    footerMenuHandle: "footer",
    customerAccessToken
  };
  const data = await storefront.query<LayoutQuery>(LAYOUT_QUERY, {
    variables,
    cache: storefront.CacheCustom({
      mode: "public",
      maxAge: 300,
      staleWhileRevalidate: 3600
    })
  });

  const customPrefixes = { BLOG: "", CATALOG: "products" };

  const headerMenu = data?.headerMenu ?
  parseMenu(data.headerMenu, customPrefixes) :
  undefined;

  const footerMenu = data?.footerMenu ?
  parseMenu(data.footerMenu, customPrefixes) :
  undefined;

  return {
    headerMenu,
    footerMenu,
    shop: {
      id: data.shop.id,
      name: data.shop.name,
      description: data.shop.description,
      ...data.shopInfo
    },
    customer: data.customer
  };
};

const getCartData = async (
storefront: Storefront<I18nBase>,
session: HydrogenSession) =>
{
  const cartId = await session.get("cartId");

  if (!cartId) {
    return null;
  }

  const variables: CartQueryVariables = { cartId };
  const { cart } = await storefront.query<CartQuery>(CART_QUERY, {
    variables,
    cache: storefront.CacheNone()
  });

  return cart;
};

export default withSentry(Root);